.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.jason-mark-logo {
  position: absolute;
  right: 5px;
  top: 140px;
  width: 38px;
}

.jason-mark-logo:hover {
  background-color: #d1d4dc22;
  border-radius: 4px;
}

@media (max-width: 569px) {
  .jason-mark-logo {
    display: none;
  }
}